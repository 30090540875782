import React, { useEffect, useState } from 'react';
import '../registrations/registration.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../component/Layout';
import SalesmanNavbar from '../../component/SalesmanNavbar';
import SalesmanNavbar1 from '../../component/SalesmanNavbar1';

const SiteRegister = () => {
    const initialFormData = {
        buildername: '',
        sitename: '',
        supervisorname: '',
        siteaddress: '',
        mobile: '',
        latitude: '',
        longitude: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchSite();
    }, []);

    const fetchSite = async () => {
        try {
            const response = await axios.get('http://localhost:5000/getSiteRegistration');
            setCustomers(response.data.data);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleEdit = (customer) => {
        setFormData(customer);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/siteRegistration', formData);

            if (response.status === 200) {
                handleClear();
                toast.success('Registration Successful');
                fetchSite();
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Data Not Registered');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClear = () => {
        setFormData(initialFormData);
    };

    const handleUpdate = async (id) => {
        try {
            const response = await axios.put(`http://localhost:5000/updateSite/${id}`, formData);

            if (response.status === 200) {
                toast.success('Record Updated Successfully');
                fetchSite(); // Update the list of customers after updating
                console.log("Success", response);
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Update Record');
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`http://localhost:5000/deleteSite/${id}`);

            if (response.status === 200) {
                toast.success('Record Deleted Successfully');
                fetchSite(); // Update the list of customers after deleting
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Delete Record');
        }
    };

    const handleGetCurrentLocation = () => {
        console.log('Current location button clicked');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log('Current location button clicked');
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setFormData({
                        ...formData,
                        latitude: latitude,
                        longitude: longitude,
                    });
                },
                (error) => {
                    console.error('Error getting current location:', error);
                    toast.error('Error getting current location');
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            toast.error('Geolocation is not supported by this browser.');
        }
    };

    const generateGoogleMapLink = () => {
        const { latitude, longitude } = formData;
        return `https://www.google.com/maps?q=${latitude},${longitude}`;
    };

    return (
        <>
            <SalesmanNavbar1 />
            <Layout>
                <div className="registration-form-container">
                    <ToastContainer />
                    <h1 style={{ textAlign: 'center', fontSize: '30px', padding: '20px' }}><strong>Site Registration</strong></h1>
                    <form>
                        {/* Form inputs */}

                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="buildername"
                                    placeholder='Builder Name'
                                    value={formData.buildername}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="sitename"
                                    placeholder='Site Name'
                                    value={formData.sitename}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="supervisorname"
                                    placeholder='Supervisor Name'
                                    value={formData.supervisorname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="siteaddress"
                                    placeholder='Site Address'
                                    value={formData.siteaddress}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="mobile"
                                    placeholder='Mobile No.'
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="latitude"
                                    placeholder='Latitude'
                                    value={formData.latitude}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="longitude"
                                    placeholder='Longitude'
                                    value={formData.longitude}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <button className='btn btn-secondary' onClick={handleGetCurrentLocation}>Current Location</button>
                            </div>
                        </div>
                        <div className=" button-container">
                            <button className='btn btn-primary' onClick={handleSubmit}>Save</button>
                            <button className='btn btn-primary' onClick={() => handleUpdate(formData.id)} style={{ marginLeft: '1rem' }}>Update</button>
                            <button className='btn btn-warning' onClick={handleClear} style={{ marginLeft: '1rem' }}>Clear</button>
                        </div>
                    </form >
                    <div className="table-container">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Builder Name</th>
                                    <th>Site Name</th>
                                    <th>Supervisor Name</th>
                                    <th>Site Address</th>
                                    <th>Mobile</th>
                                    <th>Location</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map((customer) => (
                                    <tr key={customer.id}>
                                        <td>{customer.id}</td>
                                        <td>{customer.buildername}</td>
                                        <td>{customer.sitename}</td>
                                        <td>{customer.supervisorname}</td>
                                        <td>{customer.siteaddress}</td>
                                        <td>{customer.mobile}</td>
                                        <td>
                                            <a href={generateGoogleMapLink()} target="_blank" rel="noopener noreferrer">View on Map</a>
                                        </td>
                                        <td>
                                            <button className="edit-button" onClick={() => handleEdit(customer)}>Edit</button>
                                        </td>
                                        <td>
                                            <button className="delete-button" onClick={() => handleDelete(customer.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default SiteRegister;
