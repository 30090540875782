import React, { useEffect, useState } from 'react';
import './registration.css';
import Navbar from '../../component/Navbar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../component/Layout';
import { FaEdit, FaTrash } from 'react-icons/fa';

const SupplyMaster = () => {
    const initialFormData = {
        typeofsand: '',
        price: '',
        unit: '',
        state: 'maharashtra',
        hsncode: '',
        gst: '',
        igst: '',
        sgst: '',
        cgst: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await axios.get('http://localhost:5000/getMaterialRegistration');
            setCustomers(response.data.data);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleEdit = (customer) => {
        setFormData(customer);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/materialRegistration', formData);

            if (response.status === 200) {
                handleClear();
                toast.success('Registration Successful');
                fetchCustomers();
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Data Not Registered');
        }
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };

    const handleClear = () => {
        setFormData(initialFormData);
    };

    const handleUpdate = async (id) => {
        try {
            const response = await axios.put(`http://localhost:5000/updateMaterial/${id}`, formData);

            if (response.status === 200) {
                toast.success('Record Updated Successfully');
                fetchCustomers(); // Update the list of customers after updating
                console.log("Success", response);
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Update Record');
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`http://localhost:5000/deleteMaterial/${id}`);

            if (response.status === 200) {
                toast.success('Record Deleted Successfully');
                fetchCustomers(); // Update the list of customers after deleting
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Delete Record');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'state') {
            // If state changes, reset SGST and CGST, and enable/disable inputs accordingly
            if (value !== 'maharashtra') {
                setFormData({
                    ...formData,
                    [name]: value,
                    sgst: '',
                    cgst: '',
                    igst: '', // Make IGST input visible
                });
            } else {
                setFormData({
                    ...formData,
                    [name]: value,
                    igst: '', // Hide IGST input
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const handleSelectChange = (value, name) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <Navbar />
            <Layout>
                <div className="registration-form-container">
                    <ToastContainer />
                    <h1 style={{ textAlign: 'center', fontSize: '30px', padding: '20px' }}><strong>Material Registration</strong></h1>
                    <form>
                        {/* Form inputs */}

                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="typeofsand"
                                    placeholder='Material Name'
                                    value={formData.typeofsand}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="price"
                                    placeholder='Price'
                                    value={formData.price}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <select value={formData.unit} onChange={(e) => handleSelectChange(e.target.value, 'unit')} className='select'>
                                    <option value="">Select Unit</option>
                                    <option value="Brass">Brass</option>
                                    <option value="Tonn">Tonn</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <select value={formData.state} onChange={(e) => handleSelectChange(e.target.value, 'state')} className='select'>
                                    <option value="">Select State</option>
                                    <option value="maharashtra">Maharashtra</option>
                                    <option value="karnataka">Karnataka</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="hsncode"
                                    placeholder='HSN Code'
                                    value={formData.hsncode}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="gst"
                                    placeholder='GST %'
                                    value={formData.gst}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {formData.state !== 'maharashtra' && (
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="igst"
                                        placeholder='IGST %'
                                        value={formData.igst}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            )}
                            {formData.state === 'maharashtra' && (
                                <>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="sgst"
                                            placeholder='SGST %'
                                            value={formData.sgst}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="cgst"
                                            placeholder='CGST %'
                                            value={formData.cgst}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </>
                            )}
                        </div>


                        <div className=" button-container mb-3">
                            <button className='btn btn-primary' onClick={handleSubmit}>Save</button>
                            <button className='btn btn-primary' onClick={() => handleUpdate(formData.id)} style={{ marginLeft: '1rem' }}>Update</button>
                            <button className='btn btn-warning' onClick={handleClear} style={{ marginLeft: '1rem' }}>Clear</button>
                        </div>
                    </form >
                    <div className="table-container">
                        <table className="styled-table">
                            <thead style={{textAlign:'center'}}>
                                <tr>
                                    <th>ID</th>
                                    <th>Sand Type</th>
                                    <th>Price</th>
                                    <th>Unit</th>
                                    <th>State</th>
                                    <th>HSN Code</th>
                                    <th>GST %</th>
                                    <th>SGST</th>
                                    <th>CGST</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map((customer) => (
                                    <tr key={customer.id}>
                                        <td>{customer.id}</td>
                                        <td>{customer.typeofsand}</td>
                                        <td>{customer.price}</td>
                                        <td>{customer.unit}</td>
                                        <td>{customer.state}</td>
                                        <td>{customer.hsncode}</td>
                                        <td>{customer.gst}</td>
                                        <td>{customer.sgst}</td>
                                        <td>{customer.cgst}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FaEdit className="edit-icon" onClick={() => handleEdit(customer)} style={{ color: 'green', cursor: 'pointer', fontSize: '25px' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FaTrash style={{ color: 'red', textAlign: 'center', fontSize: '25px' ,cursor: 'pointer'}} className="delete-icon" onClick={() => handleDelete(customer.id)}>Delete</FaTrash>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default SupplyMaster;
