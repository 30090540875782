// AuthProvider.js
import React, { createContext, useState, useContext } from 'react';

// Create an AuthContext
export const AuthContext = createContext();

// Create an AuthProvider component
export const AuthProvider = ({ children }) => {
    // Define the initial authentication state
    const [auth, setAuth] = useState({
        token: localStorage.getItem('token') || null, // You can initialize the token based on the local storage or any other mechanism
    });

    // Function to update the authentication state
    const updateAuth = (token) => {
        setAuth({ token });
        localStorage.setItem('token', token); // Save the token to local storage
    };

    // Function to clear authentication
    const clearAuth = () => {
        setAuth({ token: null });
        localStorage.removeItem('token'); // Remove the token from local storage
    };

    return (
        // Provide the authentication state and update functions to the children using the AuthContext.Provider
        <AuthContext.Provider value={{ auth, updateAuth, clearAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to consume the AuthContext
export const useAuth = () => useContext(AuthContext);
