import React, { useEffect, useState } from 'react';
import './registration.css';
import Navbar from '../../component/Navbar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../component/Layout';
import { FaEdit, FaTrash } from 'react-icons/fa';

const SupplyMaster = () => {
    const initialFormData = {
        gstno: '',
        pan: '',
        suppliername: '',
        mobile: '',
        sitename: '',
        address: '',
        email: '',
        architectname: '',
        latitude: '', // Add latitude field
        longitude: '', // Add longitude field
        openingbal: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [customers, setCustomers] = useState([]);
    const [supply, setSupply] = useState([]);
    const [error, setError] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        fetchCustomers();
        fetchSite();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await axios.get('http://localhost:5000/getSupplyRegistration');
            setCustomers(response.data.data);
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchSite = async () => {
        try {
            const response = await axios.get('http://localhost:5000/getSiteRegistration');
            const siteData = response.data.data.map(site => ({
                ...site,
                latitude: site.latitude, // Assuming these are the correct fields in your data
                longitude: site.longitude
            }));
            setSupply(siteData);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleEdit = (customer) => {
        setFormData(customer);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataWithLocation = { ...formData, latitude: formData.latitude, longitude: formData.longitude };

            const response = await axios.post('http://localhost:5000/supplyRegistration', formDataWithLocation);

            if (response.status === 200) {
                handleClear();
                toast.success('Registration Successful');
                fetchCustomers();
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Data Not Registered');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'sitename') {
            const selectedSupplier = supply.find((p) => p.sitename === value);
            if (selectedSupplier) {
                setFormData({
                    ...formData,
                    sitename: selectedSupplier.sitename,
                    address: selectedSupplier.siteaddress,
                    latitude: selectedSupplier.latitude,
                    longitude: selectedSupplier.longitude
                });
            } else {
                console.log('Site not found');
            }
        }
    };

    const handleClear = () => {
        setFormData(initialFormData);
    };

    const handleUpdate = async (id) => {
        try {
            const response = await axios.put(`http://localhost:5000/updateSupply/${id}`, formData);

            if (response.status === 200) {
                toast.success('Record Updated Successfully');
                fetchCustomers(); // Update the list of customers after updating
                console.log("Success", response);
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Update Record');
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`http://localhost:5000/deleteSupply/${id}`);

            if (response.status === 200) {
                toast.success('Record Deleted Successfully');
                fetchCustomers(); // Update the list of customers after deleting
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Delete Record');
        }
    };

    return (
        <>
            <Navbar />
            <Layout>
                <div className="registration-form-container">
                    <ToastContainer />
                    <h1 style={{ textAlign: 'center', fontSize: '30px', padding: '20px' }}><strong>Builder Registration</strong></h1>
                    <form>
                        {/* Form inputs */}

                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="gstno"
                                    placeholder='GST No.'
                                    value={formData.gstno}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="pan"
                                    placeholder='PAN Card No.'
                                    value={formData.pan}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="suppliername"
                                    placeholder='Builder Name'
                                    value={formData.suppliername}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="mobile"
                                    placeholder='Mobile No.'
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <select style={{ width: '100%', height: '45px', border: '1px solid #ccc', borderRadius: '5px' }}
                                    name="sitename"
                                    value={formData.sitename}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Site Name</option>
                                    {supply.map((builder) => (
                                        <option key={builder.id} value={builder.sitename}>
                                            {builder.sitename}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder='Site Address'
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="architectname"
                                    placeholder='Architecture Name'
                                    value={formData.architectname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type="text"
                                    name="openingbal"
                                    placeholder='Opening Balance'
                                    value={formData.openingbal}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className="form-group">
                                <div className={`form-group ${isVisible ? '' : 'hidden'}`}>
                                    <input
                                        type="text"
                                        name="latitude"
                                        placeholder='Lattitude'
                                        value={formData.latitude}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className={`form-group ${isVisible ? '' : 'hidden'}`}>
                                    <input
                                        type="text"
                                        name="longitude"
                                        value={formData.longitude}
                                        onChange={handleChange}
                                        required
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>

                        <div className=" button-container mb-3">
                            <button className='btn btn-primary' onClick={handleSubmit}>Save</button>
                            <button className='btn btn-primary' onClick={() => handleUpdate(formData.id)} style={{ marginLeft: '1rem' }}>Update</button>
                            <button className='btn btn-warning' onClick={handleClear} style={{ marginLeft: '1rem' }}>Clear</button>
                        </div>
                    </form >
                    <div className="table-container">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>GST No.</th>
                                    <th>PAN No.</th>
                                    <th>Supplier Name</th>
                                    <th>Mobile</th>
                                    <th>Site Name</th>
                                    <th>Address</th>
                                    <th>Email</th>
                                    <th>Architect Name</th>
                                    <th>Lat</th>
                                    <th>Long</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map((customer) => (
                                    <tr key={customer.id}>
                                        <td>{customer.id}</td>
                                        <td>{customer.gstno}</td>
                                        <td>{customer.pan}</td>
                                        <td>{customer.suppliername}</td>
                                        <td>{customer.mobile}</td>
                                        <td>{customer.sitename}</td>
                                        <td>{customer.address}</td>
                                        <td>{customer.email}</td>
                                        <td>{customer.architectname}</td>
                                        <td>{customer.latitude}</td>
                                        <td>{customer.longitude}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FaEdit className="edit-icon" onClick={() => handleEdit(customer)} style={{ color: 'green', cursor: 'pointer', fontSize: '25px' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FaTrash style={{ color: 'red', textAlign: 'center', fontSize: '25px', cursor: 'pointer' }} className="delete-icon" onClick={() => handleDelete(customer.id)}>Delete</FaTrash>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default SupplyMaster;
