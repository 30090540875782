import React, { useEffect, useState } from 'react';
import './registration.css';
import Navbar from '../../component/Navbar';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../component/Layout';
import { FaEdit, FaTrash, FaSave } from 'react-icons/fa';

const DriverMaster = () => {
    const initialFormData = {
        username: '',
        address: '',
        mobile: '',
        licenceno: '',
        aadhar: '',
        expairydate: '',
        password: '',
        cpassword: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await axios.get('http://localhost:5000/getDriverRegistrations');
            setCustomers(response.data.data);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleEdit = (customer) => {
        setFormData(customer);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:5000/driverRegistration', formData);

            if (response.status === 200) {
                handleClear();
                toast.success('Registration Successful');
                fetchCustomers();
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Data Not Registered');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClear = () => {
        setFormData(initialFormData);
    };

    const handleUpdate = async (id) => {
        try {
            const response = await axios.put(`http://localhost:5000/updateDriver/${id}`, formData);

            if (response.status === 200) {
                toast.success('Record Updated Successfully');
                fetchCustomers(); // Update the list of customers after updating
                console.log("Success", response);
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Update Record');
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`http://localhost:5000/deleteDriver/${id}`);

            if (response.status === 200) {
                toast.success('Record Deleted Successfully');
                fetchCustomers(); // Update the list of customers after deleting
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to Delete Record');
        }
    };

    return (
        <>
            <Navbar />
            <Layout>
                <div className="registration-form-container">
                    <ToastContainer />
                    <h1 style={{ textAlign: 'center', fontSize: '30px', padding: '20px' }}><strong>Driver Registration</strong></h1>
                    <form>
                        {/* Form inputs */}

                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="username"
                                    placeholder='Driver Name'
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder='Address'
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="mobile"
                                    placeholder='Mobile No.'
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="licenceno"
                                    placeholder='Licence No'
                                    value={formData.licenceno}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="aadhar"
                                    placeholder='Aadhar No.'
                                    value={formData.aadhar}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="expairydate"
                                    placeholder='Licence Expairy Date [YYYY-MM-DD]'
                                    value={formData.expairydate}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="password"
                                    placeholder='Password'
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="cpassword"
                                    placeholder='Confirm Password'
                                    value={formData.cpassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                            </div>
                        </div>

                        <div className=" button-container mb-3">
                            <button className='btn btn-primary' onClick={handleSubmit}><FaSave className="icon" /><span>Save</span></button>
                            <button className='btn btn-primary' onClick={() => handleUpdate(formData.id)} style={{ marginLeft: '1rem' }}>Update</button>
                            <button className='btn btn-warning' onClick={handleClear} style={{ marginLeft: '1rem' }}>Clear</button>
                        </div>
                    </form >
                    <div className="table-container">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Driver Name</th>
                                    <th>Address</th>
                                    <th>Mobile</th>
                                    <th>Licence No</th>
                                    <th>Aadhar No</th>
                                    <th>Licence Expiary Date</th>
                                    <th>Password</th>
                                    <th>C Password</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map((customer) => (
                                    <tr key={customer.id}>
                                        <td>{customer.id}</td>
                                        <td>{customer.username}</td>
                                        <td>{customer.address}</td>
                                        <td>{customer.mobile}</td>
                                        <td>{customer.licenceno}</td>
                                        <td>{customer.aadhar}</td>
                                        <td>{customer.expairydate}</td>
                                        <td>{customer.password}</td>
                                        <td>{customer.cpassword}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FaEdit className="edit-icon" onClick={() => handleEdit(customer)} style={{ color: 'green', cursor: 'pointer', fontSize: '25px' }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <FaTrash style={{ color: 'red', textAlign: 'center', fontSize: '25px', cursor: 'pointer' }} className="delete-icon" onClick={() => handleDelete(customer.id)}>Delete</FaTrash>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default DriverMaster;
